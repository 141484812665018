<template>
  <svg
    :height="`${height}px` || '24px'"
    :width="`${width}px` || '24px'"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_806_148)">
      <path
        d="M23.4698 6.63203C23.3321 6.1233 23.0636 5.65951 22.6909 5.28684C22.3183 4.91417 21.8545 4.64562 21.3458 4.50794C19.4833 4 11.9876 4 11.9876 4C11.9876 4 4.49157 4.01537 2.62913 4.52331C2.1204 4.661 1.65661 4.92956 1.28395 5.30225C0.911299 5.67494 0.642778 6.13875 0.505132 6.6475C-0.0582116 9.95669 -0.276743 14.9991 0.520601 18.1759C0.658261 18.6847 0.926788 19.1485 1.29944 19.5211C1.6721 19.8938 2.13588 20.1623 2.6446 20.3C4.50704 20.808 12.0029 20.808 12.0029 20.808C12.0029 20.808 19.4987 20.808 21.361 20.3C21.8698 20.1624 22.3336 19.8938 22.7063 19.5211C23.0789 19.1485 23.3475 18.6847 23.4851 18.1759C24.0793 14.8621 24.2624 9.82281 23.4698 6.63212V6.63203Z"
        fill="#FF0000"
      />
      <path d="M9.60197 16.0056L15.8202 12.4039L9.60197 8.80225V16.0056Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_806_148">
        <rect width="24" height="16.875" fill="white" transform="translate(0 4)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
