<template>
  <svg
    :height="`${height}px` || '24px'"
    :width="`${width}px` || '24px'"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.2223 0.00165573H1.77094C0.810916 -0.00918651 0.011701 0.771453 0 1.73147V22.2678C0.0112716 23.2283 0.810379 24.0098 1.77094 23.9995H22.2223C23.1852 24.0116 23.9871 23.2302 24 22.2678V1.73008C23.9871 0.768018 23.1841 -0.0125151 22.2223 0.000152031V0.00165573ZM3.55797 20.4494H7.11777V8.99675H3.55797V20.4494ZM5.33975 7.43127C4.20765 7.43127 3.27564 6.49981 3.27564 5.36771C3.27564 4.2356 4.20722 3.30403 5.33932 3.30403C6.47131 3.30403 7.40278 4.23539 7.40299 5.36728C7.40299 6.49916 6.47164 7.43117 5.33975 7.43127ZM16.8934 20.4494H20.4495L20.451 14.1669C20.451 11.0826 19.7864 8.71195 16.1822 8.71195C14.8062 8.66085 13.509 9.37332 12.8139 10.5618H12.766V8.99675H9.35224V20.4489H12.9083V14.7836C12.9083 13.2896 13.1917 11.8428 15.0439 11.8428C16.8697 11.8428 16.8934 13.5523 16.8934 14.8803V20.4494Z"
      fill="white"
    />
    <path
      d="M5.33975 7.43127C4.20765 7.43127 3.27564 6.49981 3.27564 5.36771C3.27564 4.2356 4.20722 3.30403 5.33932 3.30403C6.47131 3.30403 7.40278 4.23539 7.40299 5.36728C7.40299 6.49916 6.47164 7.43117 5.33975 7.43127Z"
      fill="white"
    />
    <path d="M7.11777 20.4494H3.55797V8.99675H7.11777V20.4494Z" fill="white" />
    <path
      d="M20.4495 20.4494H16.8934V14.8803C16.8934 13.5523 16.8697 11.8428 15.0439 11.8428C13.1917 11.8428 12.9083 13.2896 12.9083 14.7836V20.4489H9.35224V8.99675H12.766V10.5618H12.8139C13.509 9.37332 14.8062 8.66085 16.1822 8.71195C19.7864 8.71195 20.451 11.0826 20.451 14.1669L20.4495 20.4494Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.2223 0.00165573H1.77094C0.810916 -0.00918651 0.011701 0.771453 0 1.73147V22.2678C0.0112716 23.2283 0.810379 24.0098 1.77094 23.9995H22.2223C23.1852 24.0116 23.9871 23.2302 24 22.2678V1.73008C23.9871 0.768018 23.1841 -0.0125151 22.2223 0.000152031V0.00165573ZM3.55797 20.4494H7.11777V8.99675H3.55797V20.4494ZM5.33975 7.43127C4.20765 7.43127 3.27564 6.49981 3.27564 5.36771C3.27564 4.2356 4.20722 3.30403 5.33932 3.30403C6.47131 3.30403 7.40278 4.23539 7.40299 5.36728C7.40299 6.49916 6.47164 7.43117 5.33975 7.43127ZM16.8934 20.4494H20.4495L20.451 14.1669C20.451 11.0826 19.7864 8.71195 16.1822 8.71195C14.8062 8.66085 13.509 9.37332 12.8139 10.5618H12.766V8.99675H9.35224V20.4489H12.9083V14.7836C12.9083 13.2896 13.1917 11.8428 15.0439 11.8428C16.8697 11.8428 16.8934 13.5523 16.8934 14.8803V20.4494Z"
      fill="#0A66C2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
